import React from "react";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";
import CardGroup from "./components/CardGroup";
import courses from "./data/courses.json";

const Training = () => (
  <React.Fragment>
    <Helmet>
      <title>Aspiricity - Training Services</title>
    </Helmet>
    <Header>
      <h2>Training</h2>
    </Header>
    <Section bgColor="bg-light" textColor="text-dark">
      <p className="lead">
        <span className="badge badge-primary">On Sale</span>
        {" "}
        Our Black Friday Sale is on now with all our courses up to 70% Off! Improve your software testing capabilities with Aspiricity’s training. 
      </p>
      <p className="lead">
        <a href="https://www.udemy.com/course/agile_software_testing/?couponCode=AST_BLACK_65">
          {" "}
          <span className="badge badge-secondary">New</span> Click here to check out
          Agile Software Testing course now available on Udemy.{"  "}
        </a>
      </p>
      <p>
        Whether you are a company looking to significantly improve the testing
        capabilities of your development team, an experienced tester looking to
        bolster your skills and resume or someone looking to start a software
        testing career, Aspiricity’s high quality training is the right option
        for you.
      </p>
      <hr />
      <p className="lead">
        Our Online Courses:
      </p>
      <CardGroup cards={courses} />
      <p>
        ... and we'll be adding other software development and quality assurance
        courses and tutorials regularly. Check back here for more details, or
        contact us to be notified about the course release schedule.
      </p>
      <br />
    </Section>
    <Footer />
  </React.Fragment>
);

export default Training;
